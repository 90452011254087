import React from 'react';
import ProductCart from './Warenkorb';

const ShoopingCart = () => {
  return (
    <div>
        <ProductCart />
    </div>
  );
}

export default ShoopingCart;
