import React from 'react'
import ProductKinder from '../../Products/Category/Kinder/Kinder'

export default function Kinder() {
  return (
    <>
    <div className='bg-warning'><h2 className='text-center'>Kinder</h2></div>
    <ProductKinder />
    </>
  )
}