import React from 'react'
import ProductHerren from '../../Products/Category/Herren/Herren'

export default function Herren() {
  return (
    <>
    <div className='bg-info'><h2 className='text-center'>Herren</h2></div>
    <ProductHerren />
    </>
  )
}