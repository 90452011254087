import React from "react";
import ContactForm from "../Contact/Contact";

import "../../sass/Pages.scss"

export default function Contact() {
  return (
    <>
    <div className="contact">
      <h1>Kontakt</h1>
      <ContactForm />
    </div>
    
    </>
  );
}