import React from 'react'
import ProductHaushalt from '../../Products/Category/Haushalt/Haushalt'

export default function Haushalt() {
  return (
    <>
    <div className='bg-secondary'><h2 className='text-center'>Haushalt</h2></div>
    <ProductHaushalt />
    </>
  )
}