import React from 'react'
import ProductDamen from '../../Products/Category/Damen/Damen'

export default function Damen() {
  return (
    <>
    <div className='bg-danger'><h2 className='text-center'>Damen</h2></div>
    <ProductDamen />
    </>
  )
}
